<template>
  <div class="position-ticket font-inconsolata">
    <p class="fs-18 fw-600 mb-0">Nomor Ticket</p>
    <p class="fs-62 fw-700">{{numberTicket}}</p>
  </div>
</template>

<script>
export default {
  props: {
    numberTicket: {
      type: String,
      default: ''
    }
  }
}
</script>
