<template>
    <div class="ticket-show-doctor text-left">
        <card-detail v-for="(list, index) in listCard" :detail="list" :key="index"></card-detail>
    </div>
</template>
<script>
import CardDetail from './CardDetail.vue'
export default {
  components: {
    CardDetail
  },
  props: {
    listCard: {
      type: Array,
      default: () => []
    }
  }
}
</script>
