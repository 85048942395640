<template>
    <card-kiosk>
        <column-set>
            <template v-slot:leftCard>
               <!-- <ticket-check-in /> -->
               <list-card-Doctor :listCard="listDokter"></list-card-Doctor>
            </template>
            <template v-slot:rightCard>
                <!-- <ticket-information /> -->
                <div class="text-left check-information-right d-flex align-items-start flex-column h-100 ">
                     <div  class="mb-auto">
                      <img src="@/assets/img/warning.png" width="60">
                      <p class="c-deep fs-28 fw-600">Lokasi check-in tidak sesuai</p>
                      <p class="c-primary fs-22 fw-600">Silahkan check-in pada area sesuai dengan petunjuk lokasi di samping</p>
                     </div>
                    <div class="text-center w-100">
                        <button-time-out class="fs-18" :time="config.delay.timeOutCancel" @click="goToRoute()"/>
                    </div>
                </div>
            </template>
        </column-set>
    </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import ColumnSet from '@/components/kioskCheckIn/ColumnSet.vue'
import ButtonTimeOut from '@/components/Button/ButtonTimeOut.vue'
import ListCardDoctor from '@/components/kioskCheckIn/Doctor/List.vue'
import { useSelector } from '@xstate/vue'
export default {
  components: {
    CardKiosk,
    ColumnSet,
    ButtonTimeOut,
    ListCardDoctor
  },
  methods: {
    goToRoute () {
      this.$emit('selesai', true)
    }
  },
  data () {
    const infoTicket = (state) => {
      return state.context.notThisCluster
    }
    const infoDetailConfig = (state) => {
      return state.context.config
    }
    const infoDetail = useSelector(window.fetchKioskCheckIn, infoTicket)
    const infoConfig = useSelector(window.fetchKioskCheckIn, infoDetailConfig)
    return {
      config: infoConfig,
      listDokter: infoDetail
    }
  }
}
</script>
