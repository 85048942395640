<template>
    <div class="ticket-show-doctor text-left">
        <doctor-check-box v-for="list,index in listDoctor" :detail="list" :index-key="index" :key="index"></doctor-check-box>
    </div>
</template>

<script>
import DoctorCheckBox from './DoctorCheckBox.vue'
export default {
  components: {
    DoctorCheckBox
  },
  props: {
    listDoctor: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style>

</style>
