<template>
  <div class="d-flex h-100 center-data ">
    <div class="card-data-remind text-left">
        <label class="fw-600 fs-28 c-deep mb-0">Anda akan check-in ke
            <span class="fw-700">Jantung & Pembuluh Darah</span>
        </label>
        <div class="mt-5">
            <label class="fs-22 fw-500 c-primary mb-2 line-h27">Sisa jadwal booking yang belum Anda selesaikan:</label>
            <div class="template-grid-dokter cgray-B mb-3" v-for="(list, index) in listDokter" :key="index">
                <div class="fs-18 ">
                    <p class="fw-700 mb-0">{{list.name}}</p>
                    <p class="fw-500 mb-0">{{list.spesialis}}</p>
                </div>
                <span class="fs-18 time-remind-card mt-1">
                    {{list.time}}
                </span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listDokter: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dokterList: 1
    }
  },
  mounted () {
    if (this.$route.query) {
      if (this.$route.query.list === '2') {
        this.dokterList = 2
      } else if (this.$route.query.list === '3') {
        this.dokterList = 3
      }
    }
  }
}
</script>

<style>

</style>
