<template>
  <label class="fs-7vh fw-700 c-deep position-relative line-63" style="z-index: 1; filter: drop-shadow(4px 6px 6px white);"
    >Silakan scan QR Code Anda untuk mulai mengantri</label
  >
  <img src="@/assets/img/new_qr_scan.gif" class="image-scanner" alt="" />
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style>

</style>
