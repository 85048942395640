<template>
    <card-kiosk>
        <div class="position-absolute position-loading">
          <img src="@/assets/img/spinner.png" alt="" class="spinner-spin" width="80" >
          <div class="mt-5 ">
            <span class="fs-16 fw-500 cgray-D">{{text}}...</span>
            <!-- <div class="dots-3 mt-1"></div> -->
            </div>
          </div>
    </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import { mapState } from 'vuex'
// import io from 'socket.io-client'
export default {
  components: {
    CardKiosk
  },
  data () {
    return {
      text: 'Memeriksa tiket'
    }
  },
  computed: {
    ...mapState('KioskCheckIn', {
      ticket: state => state.ticket
    })
  },
  mounted () {
    if (window.loadingType === 2) {
      this.text = 'Memasukkan antrian'
    } else if (window.loadingType === 1) {
      this.text = 'Memeriksa tiket'
    }
    // if (window.checkTicket === 1) {
    //   this.text = 'Memeriksa Tiket'
    //   if (window.ticket === 'success') {
    //     this.toRoute('checkTicket')
    //   // check ticket to ticket antrian
    //   } else if (window.ticket === 'wrong') {
    //     this.toRoute('failedCheckTicket')
    //   // check ticket but wrong cluster
    //   } else if (window.ticket === 'double') {
    //     this.toRoute('checkTicketOther')
    //   } else if (window.ticket === 'remind') {
    //     this.toRoute('checkRemindTicket')
    //   } else {
    //     this.toRoute('checkInError')
    //   // check ticket but failed
    //   }
    // } else if (window.checkTicket === 2) {
    //   this.text = 'Memasukkan antrian'
    //   // correct ticket
    //   if (window.ticket === 'double') {
    //     this.toRoute('successCheckTicketOther')
    //   } else {
    //     this.toRoute('successCheckTicket')
    //   }
    // }
  },
  methods: {
    toRoute (name) {
      setTimeout(() => {
        this.$router.push({ name })
      }, 4000)
    }
  }
}
</script>
