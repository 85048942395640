<template>
  <div class="card-dokter-and-room mb-12">
    <p class="c-deep fs-18 fw-600 mb-0">{{infoDokter.dokterName}}</p>
    <p class="c-deep fs-18 fw-600">{{infoDokter.spesialis}}</p>
    <div class="row cgray-A fs-22 fw-600 mx-3">
        <div class="col">
            <img src="@/assets/icon/room.svg" >
            {{infoDokter.room}}
        </div>
        <div class="col ">
           <template v-if="infoDokter.isLate && !isInfoData">
               <img src="@/assets/icon/time-red.svg" >
            </template>
            <template v-else>
                <img src="@/assets/icon/time.svg" >
            </template>
             <span :class="infoDokter.isLate && !isInfoData ? 'c-warning-A': ''">{{infoDokter.sessionStart}}</span>
        </div>
    </div>
    <div v-if="infoDokter.isLate" class="c-warning-A fs-16 fw-600 mx-5 " :class="isInfoData ? 'line-normal': ''">
      Check-in sudah melebihi waktu appointment, akan ada penyesuaian terhadap Antrian Anda
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infoDokter: {
      type: Object,
      default: () => {}
    },
    isInfoData: {
      type: Boolean,
      default: false
    }
  }
}
</script>
