<template>
    <div class="checkin-header">
        <div class="label-kiosk fs-58 font-weight-bold">
            <div class="label-kiosk-red">
                <label class="m-auto">KIOSK CHECK-IN</label>
            </div>
        </div>
        <div class="fs-38 fw-700 text-right">
            <label class="mt-40">{{clusterInfo}}</label>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    clusterInfo: {
      type: String,
      default: 'Penyakit Dalam'
    }
  }
}
</script>
