<template>
  <div class="card-dokter-check">
    <p class="fs-18 c-deep fw-700 m-0">{{detail.dokterName}}</p>
    <div class="d-flex flex-row">
      <div><img src="@/assets/icon/pin.svg" width="18" class="mr-2"></div>
      <div class="cgray-B fs-18 fw-500">{{detail.floor}} - {{detail.cluster}} </div>
    </div>
    <div class="d-flex flex-row" v-if="detail.clusterRoom">
      <div><img src="@/assets/icon/room.svg" width="18" class="mr-2"></div>
      <div class="cgray-B fs-18 fw-500">{{ detail.clusterRoom }} </div>
    </div>
    <div class="d-flex flex-row">
      <div><img src="@/assets/icon/time.svg" width="18" class="mr-2"></div>
      <div class="cgray-B fs-18 fw-500">{{detail.sessionStart}} </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
