<template>
    <div class="text-center position-relative h-100 d-flex flex-column" :class="isLate ? 'check-information-late' : 'check-information-right'">
      <div class="text-left" v-if="context.isShowWarning">
         <img class="mb-4" src="@/assets/img/warning.png" width="60" v-if="!isMultiple">
         <img class="mb-4" src="@/assets/img/information.png" width="60" v-else>
          <p class="c-primary fs-28 fw-600 line-normal" v-if="!isMultiple">Nomor tiket Anda sudah terdaftar di antrian:</p>
          <p class="c-primary fs-28 fw-600 line-normal" v-else>Nomor tiket Anda sudah terdaftar pada antrian di samping</p>
      </div>
      <div v-else class="padding-x-28">
        <animation-check />
        <p class="fs-38 fw-600 c-deep ">Check-in Berhasil</p>
        <p class="c-primary fs-22 fw-600 " v-if="!isLate">Silakan menunggu nomor antrian Anda dipanggil</p>
         <!-- <p class="c-warning-A fs-22 fw-600 mb-0" v-else>Check-in sudah melebihi waktu appointment, akan ada penyesuaian terhadap Antrian Anda</p> -->
      </div>
       <slot></slot>
        <div class="bottom-info mt-auto">
            <p class="c-warning-A fs-16 fw-600" v-if="!context.isShowWarning && ( !isLate || isMultiple)">Nomor Tiket akan dipanggil sesuai slot appointment</p>
             <p class="c-primary fw-600" v-else :class="!context.isShowWarning ? 'fs-16' : 'fs-22'">Silakan menunggu nomor tiket Anda dipanggil</p>
            <button-time-out class="fs-18" :time="context.delayTimeout.timeOutCancel" @click="goToRoute()"/>
        </div>
    </div>
</template>
<script>
import AnimationCheck from '@/components/Button/AnimationCheck.vue'
import ButtonTimeOut from '@/components/Button/ButtonTimeOut.vue'
import { useSelector } from '@xstate/vue'
import ENUM_SETUP from '@/enum/typeSetup'
import { isArray } from 'xstate/lib/utils'
export default {
  data () {
    const stateKiosk = (state) => {
      return {
        isShowWarning: state.context.typeTicket !== ENUM_SETUP.SUCCESS_TICKET,
        delayTimeout: state.context.config.delay,
        selectData: state.context.thisCluster
      }
    }
    const contextData = useSelector(window.fetchKioskCheckIn, stateKiosk)
    return {
      context: contextData
      // isShowWarning: isWarning,
      // delayTimeout: infoConfig
    }
  },
  computed: {
    isMultiple () {
      const selectData = this.context.selectData
      if (selectData.length > 1) return true
      return false
    },
    isLate () {
      const selectData = this.context.selectData
      if (isArray(selectData) && selectData.length === 1 && selectData.some(_item => _item.isLate)) return true
      return false
    }
  },
  components: {
    AnimationCheck,
    ButtonTimeOut
  },
  methods: {
    goToRoute () {
      this.$emit('endProcess')
    }
  }
}
</script>
