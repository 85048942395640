<template>
    <card-kiosk>
        <column-set>
            <template v-slot:leftCard>
               <!-- <ticket-check-in /> -->
               <list-doctor-check :list-doctor="dataDetail"></list-doctor-check>
            </template>
            <template v-slot:rightCard>
                <!-- <ticket-information /> -->
                <info-check-ticket :disabled-button="buttonDisabled" :delay-button="config.delay" :cluster-info="config.cluster" @next-step="lanjutkan" @end-step="selesai"></info-check-ticket>
            </template>
        </column-set>
    </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import ColumnSet from '@/components/kioskCheckIn/ColumnSet.vue'
import ListDoctorCheck from '@/components/kioskCheckIn/moreTicket/ListDoctorCheck.vue'
import InfoCheckTicket from '@/components/kioskCheckIn/moreTicket/InfoCheckTicket.vue'
// import TicketCheckIn from '@/components/kioskCheckIn/Ticket.vue'
// import TicketInformation from '@/components/kioskCheckIn/InformationCheckIn.vue'
import { useSelector } from '@xstate/vue'
export default {
  components: {
    CardKiosk,
    ColumnSet,
    InfoCheckTicket,
    ListDoctorCheck
    // TicketCheckIn,
    // TicketInformation
  },
  mounted () {

  },
  methods: {
    selesai () {
      this.$emit('selesai')
    },
    lanjutkan () {
      this.$emit('lanjutkan')
    }
  },
  data () {
    const infoDokter = (state) => {
      return state.context.thisCluster
    }
    const infoDetailConfig = (state) => {
      return state.context.config
    }
    const infoDetail = useSelector(window.fetchKioskCheckIn, infoDokter)
    const infoConfig = useSelector(window.fetchKioskCheckIn, infoDetailConfig)
    return {
      config: infoConfig,
      dataDetail: infoDetail
    }
  },
  computed: {
    buttonDisabled () {
      if (!this.dataDetail) return false
      const isNotCheckIn = this.dataDetail.every(_item => !_item.isCheckIn)
      return this.dataDetail.length > 0 && isNotCheckIn
    }
  }
}
</script>
