<template>
    <card-kiosk>
        <column-set>
            <template v-slot:leftCard>
                <div class="ticket-show-doctor text-center">
                    <info-doctor-and-room :info-dokter="list" v-for="list, index in listDokter" :key="index"/>
                </div>
               <!-- <ticket-check-in /> -->
               <!-- <list-doctor-check :list-doctor="list"></list-doctor-check> -->
            </template>
            <template v-slot:rightCard>
                <!-- <ticket-information /> -->
                 <check-in-detail @end-process="goToRoute">
                        <number-ticket :number-ticket="listDokter[0].ticket"/>
                     <!-- <info-doctor-and-room :info-dokter="dokter"/> -->
                </check-in-detail>
            </template>
        </column-set>
    </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import ColumnSet from '@/components/kioskCheckIn/ColumnSet.vue'
import InfoDoctorAndRoom from '@/components/Status/InfoDoctorAndRoom.vue'
import CheckInDetail from '@/components/kioskCheckIn/TicketCheckInSuccess.vue'
import NumberTicket from '@/components/kioskCheckIn/moreTicket/Number.vue'
import { useSelector } from '@xstate/vue'
// import TicketCheckIn from '@/components/kioskCheckIn/Ticket.vue'
// import TicketInformation from '@/components/kioskCheckIn/InformationCheckIn.vue'
export default {
  components: {
    CardKiosk,
    ColumnSet,
    CheckInDetail,
    InfoDoctorAndRoom,
    NumberTicket
    // ListDoctorCheck
    // TicketCheckIn,
    // TicketInformation
  },
  methods: {
    goToRoute () {
      this.$emit('selesai')
    }
  },
  data () {
    const infoTicket = (state) => {
      return state.context.thisCluster
    }
    const infoDetail = useSelector(window.fetchKioskCheckIn, infoTicket)
    return {
      listDokter: infoDetail
    }
  }
}
</script>
