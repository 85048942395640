<template>
    <card-kiosk>
        <column-set>
            <template v-slot:leftCard>
               <card-remind :list-dokter="listDokter"></card-remind>
            </template>
            <template v-slot:rightCard>
                <!-- <ticket-information /> -->
                <div class="text-left check-information-right d-flex align-items-start flex-column h-100">
                     <div class="mb-auto">
                      <img class="mb-2" src="@/assets/img/warning.png" width="60">
                      <p class="c-deep fs-32 fw-700">Apakah Anda yakin ingin melanjutkan check-in?</p>
                     </div>
                    <div class="row w-100">
                        <div class="col-6">
                        <button-linear-cancel :data-time-out="delayButton" @cancel="goToRoute"  ref="button-cancel" :class="'btn-batalkan-checkIn btn-block  fs-18 fw-600'"/>
                        </div>
                        <div class="col-6">
                        <button
                            class="btn-lanjutkan-checkIn fs-18 fw-600 btn-block" @click="nextStep"
                        >
                            LANJUTKAN
                        </button>
                        </div>
                    </div>
                </div>
            </template>
        </column-set>
    </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import ColumnSet from '@/components/kioskCheckIn/ColumnSet.vue'
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'
import CardRemind from '@/components/kioskCheckIn/Doctor/CardRemind.vue'
import { useSelector } from '@xstate/vue'
// import TicketCheckIn from '@/components/kioskCheckIn/Ticket.vue'
// import TicketInformation from '@/components/kioskCheckIn/InformationCheckIn.vue'
export default {
  components: {
    CardKiosk,
    ColumnSet,
    ButtonLinearCancel,
    CardRemind
    // TicketCheckIn,
    // TicketInformation
  },
  methods: {
    goToRoute () {
      this.$emit('selesai')
      // this.$router.push({ name: 'kioskCheckIn' })
    },
    nextStep () {
      this.$emit('lanjutkan')
      // window.checkTicket = 2
      // this.$router.push({ name: 'loading' })
    }
  },
  data () {
    const infoTicket = (state) => {
      return state.context.notThisCluster.map(_item => {
        return {
          name: _item.unit.name,
          spesialis: '',
          time: _item.sessionStart + '-' + _item.sessionEnd
        }
      })
    }
    const infoDetailConfig = (state) => {
      return state.context.config.delay
    }
    const infoDetail = useSelector(window.fetchKioskCheckIn, infoTicket)
    const infoConfig = useSelector(window.fetchKioskCheckIn, infoDetailConfig)
    return {
      listDokter: infoDetail,
      delayButton: infoConfig
    }
  },
  mounted () {
    console.log(this.delayButton)
  }
}
</script>
