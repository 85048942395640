<template>
  <card-kiosk>
    <div class="text-center card-error-checkin mx-auto">
      <div class="padding-40 h-100 d-flex align-items-start flex-column">
        <img src="@/assets/img/error.png" width="64" class="mx-auto"/>
        <p class="c-deep fs-38 fw-600 mx-auto line-46 mt-1 margin-bottom-36">QR Code tidak dapat digunakan</p>
        <div class="row mb-4 text-left">
          <div class="col-md-12">
            <p class="c-warning-A fs-22 fw-600 margin-bottom-21 line-27">
              Hal ini mungkin terjadi karena:
            </p>
            <ul class="c-warning-A line-27 mb-0">
              <li class=" fw-500 fs-22 text-justify">
                Nomor tiket Anda belum didaftarkan pada jadwal yang tersedia
              </li>
              <li class=" fw-500 fs-22 text-justify">
                Nomor tiket sudah tidak berlaku
              </li>
              <li class=" fw-500 fs-22 text-justify">
               Sesi konsultasi Anda sudah selesai
              </li>
              <li class=" fw-500 fs-22 text-justify">
                Jam praktik sudah selesai
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-auto">
          <div class="grid-template-information margin-bottom-26">
               <img
                src="@/assets/icon/information.svg"
                class="mt-1"
                width="20"
                alt=""
              />
              <div class="c-primary fw-600 fs-18 text-justify ">
              Jika ada kendala dengan kode booking/QR Code Anda, silakan menuju
              Pendaftaran atau menghubungi staff kami
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-1">
              <img
                src="@/assets/icon/information.svg"
                class="mt-3"
                width="20"
                alt=""
              />
            </div>
            <div class="col-md-11 c-primary fw-600 fs-18 text-justify margin-bottom-26">
              Jika ada kendala dengan kode booking/QR Code Anda, silakan menuju
              Pendaftaran atau menghubungi staff kami
            </div>
          </div> -->
          <button-time-out class="fs-18" :time="config.delay.timeOutCancel" @click="goToRoute()" />
        </div>
      </div>
    </div>
  </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import ButtonTimeOut from '@/components/Button/ButtonTimeOut.vue'
import { useSelector } from '@xstate/vue'
export default {
  data () {
    const infoDetailConfig = (state) => {
      return state.context.config
    }
    const infoConfig = useSelector(window.fetchKioskCheckIn, infoDetailConfig)
    return {
      config: infoConfig
    }
  },
  components: {
    CardKiosk,
    ButtonTimeOut
  },
  methods: {
    goToRoute () {
      window.fetchKioskCheckIn.send({
        type: 'backToIdle'
      })
      // this.$router.push({ name: 'kioskCheckIn' })
      // this.$emit('selesai', true)
    }
  }
}
</script>
