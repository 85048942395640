<template>
    <card-kiosk>
        <column-set>
            <template v-slot:leftCard>
               <ticket-check-in :ticket-data="data[0]"/>
            </template>
            <template v-slot:rightCard>
                <ticket-information :delay-button="config.delay" :cluster-info="config.cluster" @next-step="lanjutkan" @end-step="selesai"/>
            </template>
        </column-set>
    </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import ColumnSet from '@/components/kioskCheckIn/ColumnSet.vue'
import TicketCheckIn from '@/components/kioskCheckIn/Ticket.vue'
import TicketInformation from '@/components/kioskCheckIn/InformationCheckIn.vue'
import { useSelector } from '@xstate/vue'
export default {
  data () {
    const infoTicket = (state) => {
      return state.context.thisCluster
    }
    const infoDetailConfig = (state) => {
      return state.context.config
    }
    const infoDetail = useSelector(window.fetchKioskCheckIn, infoTicket)
    const infoConfig = useSelector(window.fetchKioskCheckIn, infoDetailConfig)
    return {
      data: infoDetail,
      config: infoConfig
    }
  },
  components: {
    CardKiosk,
    ColumnSet,
    TicketCheckIn,
    TicketInformation
  },
  methods: {
    lanjutkan () {
      this.$emit('lanjutkan')
    },
    selesai () {
      this.$emit('selesai')
    }
  }
}
</script>
