<template>
    <div class="text-left check-information-right d-flex align-items-start flex-column h-100">
        <div class="mb-auto">
          <img src="@/assets/img/information.png" width="60">
          <p class="c-deep fs-28 fw-600">Anda memiliki lebih dari satu bookingan pada {{clusterInfo.nama}}</p>
          <p class="c-primary fs-22 fw-600">Silahkan pilih jadwal booking pada list di samping untuk melanjutkan check-in</p>

        </div>
        <div class="row w-100">
        <div class="col-6">
          <button-linear-cancel :data-time-out="delayButton" @cancel="backToMenu"  ref="button-cancel" :class="'btn-batalkan-checkIn btn-block  fs-18 fw-600'"/>
        </div>
        <div class="col-6">
          <button
            @click="nextStep"
            class="btn-lanjutkan-checkIn fs-18 fw-600 btn-block"
            :disabled="disabledButton"
          >
            LANJUTKAN
          </button>
        </div>
      </div>
    </div>
</template>
<script>
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'
import Wrapper from '@/repositories/Wrapper'
export default {
  props: {
    clusterInfo: {
      type: Object,
      default: () => {}
    },
    delayButton: {
      type: Object,
      default: () => {}
    },
    disabledButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonLinearCancel
  },
  mounted () {
    window.checkIn = new Wrapper((w) => {
      const val = w.get()
      if (val.type === 'reset') {
        this.reset()
      }
    })
  },
  methods: {
    reset () {
      this.$refs['button-cancel'].resetBackgroundLinearButton()
    },
    nextStep () {
      this.$emit('nextStep')
      // window.checkTicket = 2
      // this.$router.push({ name: 'loading' })
    },
    backToMenu () {
      this.$emit('endStep')
      // this.$router.push({ name: 'kioskCheckIn' })
    }
  }
}
</script>
