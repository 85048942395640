<template>
    <div class="position-absolute position-latency-kiosk-check-in">
      <latency-bar-icons :ping-time="pingTime" :config-color="configColor" />
    </div>
  <div class="d-flex flex-column">
    <div class="kiosk-header">
      <header-kiosk :cluster-info="stateLoad.context.config.cluster.nama"/>
    </div>
    <div class="text-center kiosk-checkin-margin-home" :class="[isHome ? 'kiosk-body': '', stateLoad.context.page == page.PAGE_IDLE_CLUSTER ? 'bg-none': '']">
        <home-check-in v-if="stateLoad.context.page == page.PAGE_IDLE_CLUSTER "/>
        <loading-ticket v-else-if="stateLoad.context.page === page.PAGE_LOADING_CLUSTER "/>
        <error-ticket v-else-if="stateLoad.context.page === page.PAGE_ERROR_CLUSTER" @selesai="goToIdle"/>
        <check-ticket v-else-if="stateLoad.context.page == page.PAGE_SINGLE_CHECKIN" @lanjutkan="nextStep" @selesai="goToIdle"/>
        <check-failed v-else-if="stateLoad.context.page == page.PAGE_WRONG_CHECK_IN" @selesai="goToIdle" />
        <check-ticket-other v-else-if="stateLoad.context.page == page.PAGE_MULTIPLE_CHECKIN" @lanjutkan="nextStep" @selesai="goToIdle"/>
        <check-remind-ticket v-else-if="stateLoad.context.page == page.PAGE_REMIND" @lanjutkan="nextStep" @selesai="goToIdle"/>
        <success-check-in v-else-if="stateLoad.context.page == page.PAGE_SUCCESS_SINGLE_CHECK_IN" @selesai="goToIdle" />
        <success-check-other-ticket
         v-else-if="stateLoad.context.page == page.PAGE_SUCCESS_MULTIPLE_CHECK_IN"
          @selesai="goToIdle"
        />
        <admin-mode v-else-if="stateLoad.context.page == page.PAGE_MODE_ADMIN" />
    </div>
    <!-- <div class="kiosk-footer" v-if="stateLoad.context.page === page.PAGE_IDLE_CLUSTER "> -->
    <div class="kiosk-footer" >
      <footer-kiosk :config-data="stateLoad.context.config.cluster"/>
    </div>
  </div>
  <!-- <overlay v-show="isErrorPage || isOffline"> -->
    <overlay v-show="!dataMachine.context.connected">
     <div class="w-100 ">
      <overlay-header :icon="icon" :header-text="$l.detectLanguage('sorrySystem')" />
     </div>
     <overlay-info />
     <overlay-footer count-down />
  </overlay>
</template>
<script>
import Overlay from '@/components/Overlay/Overlay.vue'
import OverlayHeader from '@/components/Overlay/OverlayHeader'
import OverlayFooter from '@/components/Overlay/OverlayFooterProvider'
import OverlayInfo from '@/components/Overlay/OverlayInfoProvider.vue'
import FooterKiosk from '@/components/kioskCheckIn/FooterKiosk'
import HeaderKiosk from '@/components/kioskCheckIn/HeaderKiosk'
import { mapState, mapMutations } from 'vuex'
// import { assign } from 'xstate'
// import { useMachine } from '@xstate/vue'
// import kioskCheckInRepository from '@/repositories/KioskCheckIn'
// component
import HomeCheckIn from './page/HomeCheckIn.vue'
import LoadingTicket from './page/Loading.vue'
import ErrorTicket from './page/Error.vue'
import CheckTicket from './page/CheckTicket.vue'
import CheckFailed from './page/CheckFailed'
import CheckTicketOther from './page/CheckOtherTicket.vue'
import checkRemindTicket from './page/TicketRemind.vue'
import SuccessCheckOtherTicket from './page/SuccessCheckTicketOther'
import SuccessCheckIn from './page/SuccessCheckIn.vue'
import AdminMode from './page/AdminMode.vue'
import LatencyBarIcons from '@/components/Icon/LatencyBarIcons.vue'

// import io from 'socket.io-client'
// import { TicketMachine } from '@/machine/KioskCheckTicket'
import ENUM_SETUP from '@/enum/typeSetup'

export default {
  data () {
    return {
      ticketValid: false,
      positionPage: 'idle',
      isHome: true,
      // icon: require('@/assets/icon/error.svg'),
      icon: require('@/assets/gif/cry-offline.gif'),
      socketInstance: '',
      context: {},
      scanTicket: true,
      isType: 0,
      // stateLoad: state,
      page: ENUM_SETUP,
      tempText: '',
      resultText: ''

    }
  },
  watch: {
    $route: function (val) {
      this.isHome = this.checkHome(val.name)
    }
  },
  computed: {
    ...mapState('DocumentHandler', {
      isErrorPage: state => state.isErrorPage,
      isOffline: state => state.isOffline,
      pingTime: state => state.pingTime
    }),
    configColor () {
      return this.stateLoad && this.stateLoad.context && this.stateLoad.context.config.serverConfig && this.stateLoad.context.config.serverConfig.latencyIndicator ? this.stateLoad.context.config.serverConfig.latencyIndicator : {}
    }
    // pageinfo () {
    //   console.log('-->', this.stateLoad)
    //   return this.stateLoad && this.stateLoad.context && this.stateLoad.context.page
    //     ? this.stateLoad.context.page : ENUM_SETUP.PAGE_IDLE_CLUSTER
    // }
  },
  components: {
    FooterKiosk,
    HeaderKiosk,
    OverlayHeader,
    OverlayFooter,
    Overlay,
    OverlayInfo,
    HomeCheckIn,
    LoadingTicket,
    ErrorTicket,
    CheckTicket,
    CheckFailed,
    CheckTicketOther,
    checkRemindTicket,
    SuccessCheckOtherTicket,
    SuccessCheckIn,
    AdminMode,
    LatencyBarIcons
  },
  props: {
    dataMachine: {
      type: Object,
      default: () => {}
    },
    stateLoad: {
      type: Object,
      default: () => {}
    }
  },
  unmounted () {
    window.fetchKioskCheckIn.stop()
  },
  methods: {
    ...mapMutations({
      setOffline: 'DocumentHandler/SET_IS_OFFLINE'
    }),
    checkHome (val) {
      if (val === 'kioskCheckIn') {
        return true
      }
      return false
    },
    upDateOnlineStatus () {
      var condition = !!navigator.onLine
      if (!condition) {
        this.setOffline(true)
      } else {
        this.setOffline(false)
      }
    },
    checkTicketValidin1Data () {
      const data = {
        type: 1
      }
      window.fetchMachineTicket.send({
        type: 'on:checked:ticket',
        loading: false,
        detail: data
      })
    },
    pageNotInCluster () {
      const data = {
        type: 5
      }
      window.fetchMachineTicket.send({
        type: 'on:checked:ticket',
        loading: false,
        detail: data
      })
    },
    remindTicketOtherPoli () {
      const data = {
        type: 3
      }
      window.fetchMachineTicket.send({
        type: 'on:checked:ticket',
        loading: false,
        detail: data
      })
    },
    checkTicketMorePoli () {
      const data = {
        type: 2
      }
      window.fetchMachineTicket.send({
        type: 'on:checked:ticket',
        loading: false,
        detail: data
      })
    },
    goToOnly1Cluster () {
      this.positionPage = 'onlyThisCluster'
      window.fetchMachineTicket.send({
        type: 'TicketOnlyThisCluster'
      })
    },
    goToFailedCluster () {
      this.positionPage = 'failedCluster'
      window.fetchMachineTicket.send({
        type: 'TicketNotThisCluster'
      })
    },
    goToMorePoli () {
      this.positionPage = 'moreCluster'
      window.fetchMachineTicket.send({
        type: 'TicketMoreInCluster'
      })
    },
    goToRemindTicket () {
      this.positionPage = 'remindTicket'
      window.fetchMachineTicket.send({
        type: 'TicketWithForget'
      })
    },
    nextStep () {
      this.ticketValid = true
      window.fetchKioskCheckIn.send({
        type: 'nextStep'
      })
    },
    onInSocket () {
      window.fetchMachineTicket.send({
        type: 'on:checkIn:ticket',
        isPage: this.isType
      })
    },
    goToIdle () {
      console.log('idle Page')
      window.fetchKioskCheckIn.send({
        type: 'backToIdle'
      })
    }

  }
}
</script>
