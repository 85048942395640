<template>
   <div class="btn-position">
        <button class="btn btn-lg btn-primary btn-block btn-maksimal-2" @click="backHome">Reload</button>
        <div class="mt-2">
               <qrcode-vue :value="JSON.stringify(qrData)" :margin="4" style="border-radius: 20px;" :size="250"/>
        </div>
        <div>
            <label class="fs-28 c-primary fw-700 text-center">{{qrData.deviceId}}</label>
        </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { useSelector } from '@xstate/vue'
export default {
  data () {
    const selectorState = (state) => {
      return state.context.deviceId && state.context.socketId ? {
        deviceId: state.context.deviceId,
        socketId: state.context.socketId
      } : {}
    }
    const context = useSelector(window.fetchMachine, selectorState)
    return {
      qrData: context
    }
  },
  components: {
    QrcodeVue
  },
  methods: {
    backHome () {
      location.reload()
    }
  }
}
</script>

<style>

</style>
