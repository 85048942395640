<template>
    <card-kiosk>
        <column-set>
            <template v-slot:leftCard>
               <div class="ticket-check-in text-center">
                   <table-antrian :data-list="dataList" :classSet="['fs-22', 'fw-600', 'c-primary', 'ellipsis-dokter']" :from-kiosk="'checkIn'" :column-table="4">
                      <div class="row-table-success sub-header-table">
                        <div class="cell left-border-radius" >
                          Appointment
                        </div>
                        <div class="cell" >
                          Check-in
                        </div>
                        <div class="cell" >
                         No.Tiket*
                        </div>
                        <div class="cell right-border-radius" >
                        Status
                        </div>
                      </div>
                   </table-antrian>
                </div>
            </template>
            <template v-slot:rightCard>
                <!-- <ticket-information /> -->
                <check-in-detail @end-process="selesai">
                     <info-doctor-and-room :info-dokter="infoData[0]" :is-info-data="true"/>
                </check-in-detail>
            </template>
        </column-set>
    </card-kiosk>
</template>
<script>
import CardKiosk from '@/components/kioskCheckIn/Card.vue'
import ColumnSet from '@/components/kioskCheckIn/ColumnSet.vue'
import TableAntrian from '@/components/Status/TableAntrianResponsiveWithout.vue'
import CheckInDetail from '@/components/kioskCheckIn/TicketCheckInSuccess.vue'
// import DataFake from '@/repositories/dummy-data'
import InfoDoctorAndRoom from '@/components/Status/InfoDoctorAndRoom.vue'
import { useSelector } from '@xstate/vue'
export default {
  data () {
    const infoTicket = (state) => {
      return state.context.thisCluster
    }
    const listSuccessTicket = (state) => {
      return state.context.listTicketSuccess
    }
    const infoDetail = useSelector(window.fetchKioskCheckIn, infoTicket)
    const successList = useSelector(window.fetchKioskCheckIn, listSuccessTicket)
    return {
      infoData: infoDetail,
      dataList: successList
    }
  },
  components: {
    CardKiosk,
    ColumnSet,
    TableAntrian,
    CheckInDetail,
    InfoDoctorAndRoom
  },
  methods: {
    selesai () {
      this.$emit('selesai')
    }
  }
}
</script>
<style>

</style>
