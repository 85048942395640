<template>
  <div class="position-relative check-information-right text-left d-flex align-items-start flex-column h-100">
     <div class="mb-auto">
    <img src="@/assets/img/information.png" width="84" alt="" />
    <p class="c-deep fs-28 fw-600">
      Anda akan check-in ke {{clusterInfo.nama}} dengan informasi tiket antrian di
      samping
    </p>
    <p class="c-primary fs-22 fw-600">Mohon cek kembali sebelum melanjutkan</p>
     </div>
    <div class="w-100">
      <p class="c-warning-A fs-14 fw-600">
        Silahkan informasikan ke staff kami jika ada kendala dalam proses
        check-in
      </p>
      <div class="row">
        <div class="col-6">
          <button-linear-cancel :data-time-out="delayButton"  @cancel="backToMenu"  ref="button-cancel" :class="'btn-batalkan-checkIn btn-block  fs-18 fw-600'"/>
        </div>
        <div class="col-6">
          <button
            class="btn-lanjutkan-checkIn fs-18 fw-600 btn-block" @click="nextStep"
          >
            LANJUTKAN
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'

export default {
  props: {
    clusterInfo: {
      type: Object,
      default: () => {}
    },
    delayButton: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ButtonLinearCancel
  },
  methods: {
    backToMenu () {
      // this.$router.push({ name: 'kioskCheckIn' })
      this.$emit('endStep')
    },
    nextStep () {
      this.$emit('nextStep')
      // window.checkTicket = 2
      // this.$router.push({ name: 'loading' })
    }
  }
}
</script>
