<template>
  <div class="footer-antrian-kioskCheckin">
    <div class="img-caredokter">
        <img src="@/assets/img/logo.png" class="img-fluid ">
    </div>
    <div class="check-text-info">
        <label class="fs-18 fw-600 d-block m-0 text-white">{{configData.info}}</label>
        <label class="fs-12 fw-600 d-block m-0 text-white">{{configData.rs}}
        </label>
    </div>
    <div class="check-footer-right">
        <div class="fs-36 fw-600 text-right">
           {{date.hour}}<span class="blinking" v-if="date.hour">:</span>{{date.minute}}
        </div>
        <div class="fs-12 fw-600 text-center line-15 margin-date-footer" v-if="date.hour">
            {{date.day}} {{date.month}} {{date.year}}
        </div>
    </div>
  </div>
</template>

<script>
import ConvertTime from '@/helpers/convertTime'

export default {
  props: {
    configData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      date: {
        day: '',
        year: '',
        month: '',
        hour: '',
        minute: ''
      }
    }
  },
  mounted () {
    setInterval(() => {
      this.date = this.convertDateTime(new Date())
    }, 1000)
  },
  methods: {
    convertDateTime (now) {
      return {
        day: now.getDate().toString().padStart(2, '0'),
        month: `${new ConvertTime().getNameMonth(now.getMonth()).slice(0, 3)}`,
        year: `'${now.getFullYear().toString().slice(-2).trim()}`,
        hour: now.getHours().toString().padStart(2, '0'),
        minute: now.getMinutes().toString().padStart(2, '0')
      }
    }
  }
}
</script>
