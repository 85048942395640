<template>
    <div class="ticket-check-in text-center font-inconsolata bg-white">
        <div class="position-relative">
            <div class="chevron-set-checkIn">
                <img src="@/assets/icon/chevron-double-down.svg" class="animate-down">
            </div>
            <p class="fs-18 fw-600"> Nomor Tiket:</p>
            <p class="fs-62 fw-700"> {{ticketData.ticket}} </p>
            <p class="fs-22 fw-600">{{ticketData.dokterName}}</p>
            <p class="fs-18 fw-600">{{ticketData.spesialis}}</p>
            <p class="fs-16 fw-500">{{ticketData.dateFormat}}
                <br>
                <span :class="ticketData.isLate ? 'c-warning-A fw-700': ''">
                  <template v-if="ticketData.isLate">
                    <img src="@/assets/img/info-warning.png">
                  </template>
                  {{ticketData.sessionStart}}
                </span>
            </p>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    ticketData: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
