<template>
  <init-with-machine :data-machine="context" :state-load="dataState"/>
</template>

<script>
import initWithMachine from './InitWithMachine.vue'
import { useSelector, useMachine } from '@xstate/vue'
import { TicketMachine } from '@/machine/KioskCheckTicket'
import ENUM_SETUP from '@/enum/typeSetup'
import { assign } from 'xstate'
import kioskCheckInRepository from '@/repositories/KioskCheckIn'
import kioskAntrianRepository from '@/repositories/KioskAntrian'
import mapperCluster from './mapper'
import { mapActions } from 'vuex'
import MoreFunction from '@/helpers/moreFunction'
export default {
  components: {
    initWithMachine
  },
  data () {
    const selectorState = (state) => {
      return state
    }
    const context = useSelector(window.fetchMachine, selectorState)
    window.ticketMachine = TicketMachine.withConfig({
      services: {
        getLabelBadge: () => {
          return kioskAntrianRepository.getAllLabels()
        },
        getTicketInfo: (context) => {
          return kioskCheckInRepository.getTicketFromAPI(context.generatedId, this.service)
        },
        getInfoAntrian: (context) => {
          return kioskCheckInRepository.getListAntrian(context.thisCluster, this.service, context.listLabels)
        },
        postCheckInTicket (context) {
          return kioskCheckInRepository.postTicketFromAPI(context.thisCluster, context.config.cluster.list)
        }
      },
      actions: {
        showToastError: () => {
          const moreFunction = new MoreFunction()
          moreFunction.createToast(false)
        },
        setCtxAllLabels: assign({
          listLabels: (_ctx, _event) => _event.data
        }),
        editCtxCheckInData: assign({
          thisCluster: (ctx, _event) => {
            const list = ctx.thisCluster
            list[_event.data.index].isCheckIn = _event.data.value
            return [...list]
          }
        }),
        setCtxStrGeneratedId: assign({
          generatedId: (context, _event) => _event.data.generateId
        }),
        setCtxIdlePage: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_IDLE_CLUSTER
          }
        }),
        setCtxAdminPage: assign({
          page: () => ENUM_SETUP.PAGE_MODE_ADMIN
        }),
        setCtxLoadingPage: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_LOADING_CLUSTER
          }
        }),
        setCtxErrorPage: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_ERROR_CLUSTER
          }
        }),
        setCtxPageWrongCheckIn: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_WRONG_CHECK_IN
          }
        }),
        setCtxPageSingleCheckIn: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_SINGLE_CHECKIN
          }
        }),
        setCtxPageMultipleCheckIn: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_MULTIPLE_CHECKIN
          }
        }),
        setCtxPageRemind: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_REMIND
          }
        }),
        setCtxSinglePageCheckIn: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_SUCCESS_SINGLE_CHECK_IN
          }
        }),
        setCtxMultiplePageCheckIn: assign({
          page: (_context, _event) => {
            return ENUM_SETUP.PAGE_SUCCESS_MULTIPLE_CHECK_IN
          }
        }),
        setCtxConfigure: assign({
          config: (_context, _event) => {
            const data = JSON.parse(localStorage.getItem('config'))
            return data
          }
        }),
        deleteCtxArrTicketNotThisCluster: assign({
          notThisCluster: (_context, _event) => {
            return []
          }
        }),
        deleteCtxObjTicketDetail: assign(() => {
          return {
            typeTicket: '',
            generatedId: '',
            hasCheckIn: [],
            thisCluster: [],
            detailTicket: {}
          }
        }),
        setCtxDetailTicket: assign({
          detailTicket: (context, _event) => {
            return _event.data
          },
          listOrder: (context, _event) => {
            return _event.list
          }
        }),
        setCtxArrReduceThisCluster: assign((context, _event) => {
          const data = mapperCluster.mapperListOrder(context.listOrder, context.config.cluster.list, context.config.serverConfig)
          return {
            notThisCluster: data.notThisCluster,
            thisCluster: data.thisCluster
          }
        }),
        // setCtxTicketNotThisCluster: assign({
        //   notThisCluster: (context, _event) => {
        //     const data = mapperCluster.mapperTicketNotThisCluster(context.listOrder, context.config.cluster.id)
        //     return data
        //   }
        // }),
        // setCtxTicketInThisCluster: assign({
        //   thisCluster: (context, _event) => {
        //     const data = mapperCluster.mapperTicketThisCluster(context.listOrder, context.config.cluster.id)
        //     return data
        //   }
        // }),
        setCtxTicketLateNotThisCluster: assign({
          notThisCluster: (context, _event) => {
            // const data = context.notThisCluster.filter(item => item.hasRemind)
            return context.notThisCluster
          }
        }),
        setCtxTicketHasCheckInThisCluster: assign({
          hasCheckIn: (context, _event) => {
            const data = mapperCluster.ticketHasCheckIn(context.thisCluster)
            // const data = context.thisCluster.filter(item => item.hasCheckIn)
            return data
          }
        }),
        setCtxTicketCheckIn: assign({
          listTicketSuccess: (context, _event) => {
            return _event.data
          }
        }),
        setCtxTypeSuccess: assign({
          typeTicket: (context, _event) => {
            return ENUM_SETUP.SUCCESS_TICKET
          }
        }),
        setCtxTypeWarning: assign({
          typeTicket: () => ENUM_SETUP.FAILED_TICKET
        }),
        editCtxOnlyCheckInThisCluster: assign({
          thisCluster: (context) => {
            return context.thisCluster.filter(_item => _item.isCheckIn)
          }
        }),
        editCtxArrOnlyArriveCluster: assign({
          thisCluster: (context) => {
            return context.thisCluster.filter(_item => _item.data.status === 'ARRIVE')
          }
        })
      },
      guards: {
        isLabel: (context) => {
          return !context.listLabels || context.listLabels.length === 0
        },
        isPasienTicket: (context, _event) => {
          return _event.data.isPasien
        },
        isAdminTicket: (context, _event) => {
          return context.config && context.config.urlAdmin === _event.data
        },
        isHaveThisCluster: (context, _event) => {
          return context.thisCluster.length > 0
        },
        ticketHaveCluster: (context, _event) => {
          return context.notThisCluster.length > 0
        },
        isAllTicketAllreadyCheckIn: (context, _event) => {
          return context.hasCheckIn.length > 0 && (context.hasCheckIn.length === context.thisCluster.length)
        },
        isHaveTicketRemind: (context, _event) => {
          // return context.notThisCluster.length > 0
          return false
        },
        isHaveTicketOnlyOnePoli: (context, _event) => {
          return context.thisCluster.length === 1
        },
        isTicketCheckInOnlyOne: (context, _event) => {
          return context.thisCluster.length === 1
        }
      },
      delays: {
        timeOutCancel: (context) => {
          return context.config.delay.timeOutCancel * 1000
        },
        timeOutLinear: (context) => {
          const { buttonLinearCancelDelay, buttonLinearCancelProgress, buttonLinearCancelAfter } = context.config.delay
          return (parseInt(buttonLinearCancelDelay) + parseInt(buttonLinearCancelProgress) + parseInt(buttonLinearCancelAfter)) * 1000
        }
      }
    })
    const modeDevTools = process.env.VUE_APP_DEVELOPMENT ? { devTools: true } : { devTools: false }
    const { service, state } = useMachine(window.ticketMachine, modeDevTools)
    window.fetchKioskCheckIn = service
    window.stateKioskCheckIn = state
    return {
      context,
      service,
      dataState: state
    }
  },
  mounted () {
    localStorage.setItem('Language', 'id')
    this.setChangeLang('id')
  },
  methods: {
    ...mapActions({
      setChangeLang: 'Language/CHANGE_LANGUAGE'
    })
  }
}
</script>

<style>

</style>
