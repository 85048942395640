import moment from 'moment'
import ConvertTime from '@/helpers/convertTime'
export default {

  mapperListOrder (list, arrCluster, serverConfig = {}) {
    const groupingLine = list.sort((_itemA, _itemB) => _itemA.id - _itemB.id)
    const reduceData = groupingLine.reduce((prev, item) => {
      const index = prev.findIndex(_item => _item.unit.id === item.unit.id)
      if (index < 0) {
        prev.push(item)
      } else {
        prev[index] = {
          ...prev[index],
          ...item
        }
      }
      return prev
    }, [])
    const data = reduceData.filter(_item => this.checkItemThisCluster(_item.unit, arrCluster))
    const notThisCluster = reduceData.filter(_item => !(data.some(_item2 => _item2.id === _item.id)))
    return {
      notThisCluster: this.mapperToDataKioskCheckIn(notThisCluster, false, {}),
      thisCluster: this.mapperToDataKioskCheckIn(data, true, arrCluster, serverConfig)
    }
  },
  mapperToDataKioskCheckIn (data, searchConfig, listCluster = [], serverConfig = {}) {
    const dateNow = moment().format('DD/MM/YYYY')
    const delayTolerance = serverConfig.delayToleranceInMinute ? (parseInt(serverConfig.delayToleranceInMinute) * 60 * 1000) : 0
    // console.log('check', dateNow)
    return data.map(_item => {
      // const late = true
      let isLate = false
      let room = ''
      if (_item.sessionStart) {
        const timeStart = `${dateNow} ${_item.sessionStart}`
        const ms = moment().diff(moment(timeStart, 'DD/MM/YYYY HH:mm:ss'))
        const d = moment.duration(ms)
        isLate = d._milliseconds > delayTolerance
        // isLate = _item.sessionStart ? moment().diff(moment(timeStart, 'DD/MM/YYYY HH:mm:ss')).format('x') : false
      }
      if (_item.unit && _item.unit.rooms) {
        _item.unit.rooms.forEach(_items => {
          if (room && _items.roomType === 'DOCTOR') room += ', '
          if (_items.roomType === 'DOCTOR') room += _items.name
        })
      }
      let clusterData = {}
      if (searchConfig) {
        const idCluster = listCluster.filter(_item2 => this.checkClusterAndKlinik(_item2, _item)).map(_item1 => _item1.id)
        clusterData = {
          idCluster: idCluster
        }
      } else {
        const checkValidDataCluster = this.checkValidCluster(_item)
        clusterData = {
          clusterRoom: room,
          ...checkValidDataCluster
          // floor:
        }
      //
      }//
      return {
        data: _item,
        id: _item.id,
        ticket: _item.ticket,
        date: _item.date,
        dateFormat: moment(_item.date).format('dddd, DD MMMM YYYY'),
        dokterName: _item.unit.name,
        sessionStart: _item.sessionStart ? _item.sessionStart.slice(0, 5) : 'EXTRA SLOT',
        sessionEnd: _item.sessionEnd,
        isCheckIn: true,
        isLate,
        generatedId: _item.generatedId,
        spesialis: _item.unit.Dokter.speciality,
        cluster: 'Cluster Dummy',
        ...clusterData,
        room: room ?? '-'
      }
    })
  },
  mapperTicketNotThisCluster (list, idCluster) {
    const data = list.filter(_item => parseInt(_item.unit.Dokter.clusterId) !== parseInt(idCluster))
    return data
  },
  mapperTicketThisCluster (list, idCluster) {
    const data = list.filter(_item => (_item.unit.rooms && _item.unit.rooms[0].clusterId === parseInt(idCluster)) || parseInt(_item.unit.Dokter.clusterId) === parseInt(idCluster))
    return data
  },
  onlyWithCluster (data) {
    const orderLines = data.orderLines
    return orderLines.filter(_item => (_item.status !== 'FINISH' && _item.status !== 'REMOVE' && (_item.unit.type === 'DOKTER' || _item.unit.type === 'RADIOLOGY' || _item.unit.type === 'MEDICAL_REHAB'))).map(_item => {
      return {
        ..._item,
        ticket: data.ticketNo,
        date: data.date,
        generatedId: data.generatedId
      }
    })
  },
  ticketHasCheckIn (list) {
    const data = list.filter(_item => ['WAITING', 'CALLING', 'SERVING', 'SKIP'].some(_item2 => _item2 === _item.data.status))
    return data
  },
  checkValidCluster (data) {
    const dataUnit = data.unit
    const dataDokter = dataUnit.Dokter
    let clusterName = ''
    let floor
    let isDiffCluster = false
    if (dataUnit.rooms && dataDokter.kliniks) {
      const diffCluster = dataUnit.rooms ? dataUnit.rooms.filter(_item => data.kliniks && !dataDokter.kliniks.some(_item2 => _item2.clusterId === _item.clusterId)) : []
      if (diffCluster.length > 0) isDiffCluster = true
    }

    if (isDiffCluster) {
      clusterName = dataUnit.rooms.reduce((prev, next) => {
        prev += next.clusterName
        return prev
      }, '')
      floor = dataUnit.rooms[0].clusterLantai
    } else {
      if (dataUnit.rooms && dataUnit.rooms.length > 0) {
        clusterName = dataUnit.rooms[0].clusterNama
        floor = dataUnit.rooms[0].clusterLantai
      } else if (dataDokter.kliniks) {
        clusterName = dataDokter.kliniks.reduce((prev, next) => {
          prev += next.name
          return prev
        }, '')
        clusterName += ', ' + dataDokter.kliniks[0].clusterNama
        floor = dataDokter.kliniks[0].clusterLantai
      }
    }
    return {
      cluster: clusterName,
      floor
    }
  },
  checkClusterAndKlinik (cluster, data) {
    const dataOrderLine = data.unit
    const isHaveInRooms = dataOrderLine.rooms ? dataOrderLine.rooms.some(_item => cluster.id === _item.clusterId) : false
    if (isHaveInRooms) return true
    if (!dataOrderLine.Dokter.kliniks) return false
    const isHaveInKlinik = dataOrderLine.Dokter.kliniks.some(_item => _item.clusterId === cluster.id)
    if (isHaveInKlinik) return true
    return false
  },
  bodyCheckIn (data) {
    // eslint-disable-next-line no-undef
    const idListCluster = data.reduce((prev, item) => {
      const pushData = item.idCluster.filter(_item2 => !prev.some(_item3 => _item3 === _item2))
      prev.push(...pushData)
      return prev
    }, [])
    const unitCheckIns = data.map(_item => {
      return {
        isLastTime: false,
        isShowTime: true,
        unitId: _item.data.unit.id
      }
    })
    return {
      connId: window.socket.id,
      generatedId: data[0].generatedId,
      unitCheckIns,
      clusterId: idListCluster
      // clusterId:
    }
  },
  checkItemThisCluster (orderLines, listConfigCluster) {
    const listConfig = listConfigCluster ?? []
    const roomUnit = orderLines.rooms ? orderLines.rooms.filter(_item => listConfig.some(_item2 => _item2.id === _item.clusterId)) : []
    if (roomUnit.length > 0) return true
    if (orderLines.rooms && orderLines.rooms.length > 0) return false
    const klinikUnit = orderLines.Dokter.kliniks ? orderLines.Dokter.kliniks.filter(_item => listConfig.some(_item2 => _item2.id === _item.clusterId)) : []
    if (klinikUnit.length > 0) return true
    return false
  },
  checkTypeBobot (data) {
    if (data.status === 'ARRIVE') return { bobot: 0, bobotType: 0 }
    const badgeAll = []
    if (data.antrian.visitBadges) {
      badgeAll.push(...data.antrian.visitBadges)
    }
    if (data.antrian.patient && data.antrian.patient.patientLabels) {
      badgeAll.push(...data.antrian.patient.patientLabels)
    }
    const dataBadge = badgeAll.reduce((prev, item) => {
      if (!item.isShowTv) return prev
      if (prev.bobot < item.bobot && item.isPriority) {
        prev.bobot = item.bobot
        prev.bobotType = item.bobotType
        prev.status = item.displayText ?? item.name
      } else if (prev.bobot === item.bobot && prev.bobotType < item.bobotType && item.isPriority) {
        prev.bobotType = item.bobotType
        prev.status = item.displayText ?? item.name
      } else if (prev.bobot === item.bobot && prev.bobotType === item.bobotType) {
        prev.status = item.displayText ?? item.name
      } else if (prev.bobot === 0) {
        prev.status = item.displayText ?? item.name
      }
      return prev
    }, { bobot: 0, bobotType: 0, status: '' })
    return {
      bobot: dataBadge.bobot,
      bobotType: dataBadge.bobotType,
      ...(dataBadge.status ? { statusText: dataBadge.status } : {})
    }
  },
  mapperReservasiOrder (data) {
    const listOrder = []
    if (data.orderLine) {
      const dataOrder = data.orderLine.filter(_item => _item.status !== 'FINISH' && _item.status !== 'REMOVE')
        .map(_item => {
          const changeMapType = this.checkTypeBobot(_item)
          const tagStatus = _item.tag && _item.tag.isShowTv
          return {
            ..._item,
            statusText: _item.status,
            ...changeMapType,
            ...(tagStatus ? { statusText: _item.tag.name } : {})
          }
        })
      listOrder.push(...dataOrder)
    }
    if (data.reservasiBooked) {
      const listReservasiBooked = data.reservasiBooked
      const listNotHaveReservasi = listReservasiBooked.filter(_item => !(_item.reservasiOrderLine && _item.reservasiOrderLine.orderLineId) && _item.reservasiStatus !== 'CANCEL').map(_item => {
        return {
          ..._item,
          sessionStart: _item.jamMulai,
          type: _item.teleconsult ? 'Telekonsul' : 'Booking',
          status: 'Booked',
          statusText: _item.reservasiStatus
        }
      })
      listOrder.push(...listNotHaveReservasi)
    }
    return listOrder
  },
  mapperPosition (listQueue, ticketNumber, objData, listBadge) {
    const mapHaveAppt = listQueue.map(_item => {
      let appointment = 0
      if (_item.sessionStart) appointment = new ConvertTime().timeToNumber(_item.sessionStart)
      if (_item.jamMulai) appointment = new ConvertTime().timeToNumber(_item.jamMulai)
      const detailBadge = listBadge.find(_item2 => _item2.enums === _item.statusText || _item2.name === _item.statusText)
      return {
        ..._item,
        timeAppointment: appointment,
        ...(detailBadge && detailBadge.displayText ? { statusText: detailBadge.displayText } : {})
      }
    })
    const sortListQueue = mapHaveAppt.sort((itemA, itemB) => {
      const bobotA = itemA.bobot && itemA.status !== 'ARRIVE' ? itemA.bobot : 0
      const bobotB = itemB.bobot && itemB.status !== 'ARRIVE' ? itemB.bobot : 0
      const bobotTypeA = itemA.bobotTypeA && itemA.status !== 'ARRIVE' ? itemA.bobotTypeA : 0
      const bobotTypeB = itemB.bobotTypeB && itemB.status !== 'ARRIVE' ? itemB.bobotTypeA : 0
      const extraSLotA = itemA.timeAppointment === 0 ? 1 : 0
      const extraSLotB = itemB.timeAppointment === 0 ? -1 : 0
      const appointment = itemA.timeAppointment - itemB.timeAppointment
      return (bobotA !== bobotB) ? (bobotB - bobotA)
        : (bobotTypeB !== bobotTypeA) ? (bobotTypeB - bobotTypeA)
          : (extraSLotA === 1 && extraSLotB === -1) ? itemA.lastCheckinTime > itemB.lastCheckinTime
            : (extraSLotA === 1) ? extraSLotA
              : (extraSLotB === -1) ? extraSLotB
                : appointment
    }).map((x, index) => {
      return {
        index: index + 1,
        ...x
      }
    })
    const indexList = sortListQueue.findIndex(item => item.antrian && item.antrian.ticketNo === ticketNumber)
    const queueList = []
    const indexRow = indexList <= 2 ? 4 - indexList : 2
    for (let i = indexList - 2; i <= indexList + indexRow; i++) {
      if (i >= 0 && sortListQueue.length > i) {
        queueList.push(this.mapperPostionKey(sortListQueue[i], indexList === i))
      }
    }
    return {
      ticket: ticketNumber,
      list: queueList,
      objectShow: objData
    }
  },
  mapperPostionKey (list, selected) {
    list.status = list.status === 'ARRIVE' ? 'Booked' : list.status
    return {
      id: list.index,
      numberAntrian: list.antrian && list.antrian.ticketNo ? list.antrian.ticketNo : '',
      time: list.lastCheckinTime ? moment(list.lastCheckinTime).format('HH:mm') : '-',
      status: list.status === 'Booked' ? `<span class="c-biru-B">${list.statusText}</span>` : list.statusText,
      ...(list.type ? { type: list.type } : {}),
      ticketNo: list.antrian && list.antrian.ticketNo && list.status !== 'Booked' ? list.antrian.ticketNo : '-',
      sessionStart: list.sessionStart ? list.sessionStart.substring(0, 5) : 'EXTRA SLOT',
      // ...(list.sessionStart ? { sessionStart: list.sessionStart.substring(0, 5) } : {}),
      checkIn: list.lastCheckinTime ? moment(list.lastCheckinTime).format('HH:mm') : '-',
      ...(selected ? { selected: true } : {})
    }
  }
}
