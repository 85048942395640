<template>
    <div class="grid-check-ticket">
        <div class="bg-left-card">
            <slot name="leftCard"></slot>
        </div>
        <div class="bg-right-card">
            <slot name="rightCard"></slot>
        </div>
    </div>
</template>
