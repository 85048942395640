import Wrapper from './Wrapper'
import ENUM_SETUP from '../enum/typeSetup'
// import axios from 'axios'
import mapperCluster from '@/views/checkInKiosk/mapper'
import apiRepository from './apiRepository'
// import mapperAntrian from '../mappers/KioskAntrianMapper'
import moment from 'moment'
const validTicket = ({ id, isAdmin }) => {
  return {
    ticketId: id,
    valid: true,
    isAdmin
  }
}

const checkURL = (url) => {
  try {
    const urlOrigin = new URL(url)
    const ticket = urlOrigin.searchParams.get('ticket')
    const admin = !urlOrigin.searchParams.get('admin')
    return {
      ticket,
      admin
    }
  } catch {
    console.log('error parse')
  }
  return {
    ticket: 1,
    admin: true
  }
}
export default {
  checkValidationTicket (context) {
    return new Promise((resolve, reject) => {
      window.checkIn = new Wrapper((w) => {
        const val = w.get()
        if (val.type === ENUM_SETUP.VALID_TICKET) {
          const detail = checkURL(context.state.link)
          resolve(validTicket({ id: detail.ticket, isAdmin: detail.admin }))
        } else if (val.type === ENUM_SETUP.INVALID_TICKET) {
          reject(new Error('error'))
        }
      })
    })
  },
  getListAntrian (context, service, labels = []) {
    return new Promise((resolve, reject) => {
      const ticketNumber = context[0].ticket
      const unitId = context[0].data.unit.id
      const nowDate = moment().format('YYYY-MM-DD')
      var paramsBooked = new URLSearchParams()
      paramsBooked.append('unitId.equals', unitId)
      paramsBooked.append('tanggalKunjungan.equals', nowDate)
      apiRepository.getReservasiOrder(paramsBooked)
        .then(_data => {
          const listReservasi = mapperCluster.mapperReservasiOrder(_data.data)
          const objTicket = ['sessionStart', 'checkIn', 'ticketNo', 'status']
          const positionOrder = mapperCluster.mapperPosition(listReservasi, ticketNumber, objTicket, labels)
          if (positionOrder && positionOrder.list.length > 0) {
            service.send({
              type: 'show:antrian',
              data: positionOrder
            })
          } else {
            reject(new Error('NOT DATA'))
          }
          service.send({
            type: 'show:antrian',
            data: []
          })
        })
      // apiRepository.getReservasiOrder(paramsBooked)
      //   .then(_resp => {
      //     const listTicket = mapperCluster.mapperReservasiOrder(_resp.data)
      //     const objTicket = ['sessionStart', 'checkIn', 'ticketNo', 'type', 'status']
      //     const positionOrder = mapperAntrian.mapperPosition(listTicket, ticketNumber, objTicket)
      //     if (positionOrder && positionOrder.list.length > 0) {
      //       service.send({
      //         type: 'show:antrian',
      //         data: positionOrder
      //       })
      //     } else {
      //       reject(new Error('NOT DATA'))
      //     }
      //   }).catch(err => reject(new Error(err)))
    })
  },
  // get Antrian list sebelumnya
  // getListAntrian (context, service) {
  //   return new Promise((resolve, reject) => {
  //     const ticketNumber = context[0].ticket
  //     const unitId = context[0].data.unit.id
  //     var params = new URLSearchParams()
  //     params.append('unitId.In', unitId)
  //     params.append('status.In', 'WAITING')
  //     params.append('status.In', 'SKIP')
  //     apiRepository.getOrderLines(params)
  //       .then(_resp => {
  //         const objTicket = ['sessionStart', 'checkIn', 'ticketNo', 'type', 'status']
  //         const positionOrder = mapperAntrian.mapperPosition(_resp.data, ticketNumber, objTicket)
  //         if (positionOrder && positionOrder.list.length > 0) {
  //           service.send({
  //             type: 'show:antrian',
  //             data: positionOrder
  //           })
  //         } else {
  //           reject(new Error('NOT DATA'))
  //         }
  //       }).catch(err => reject(new Error(err)))
  //   })
  // },
  getTicketFromAPI (generatedId, service) {
    // no action
    return new Promise((resolve, reject) => {
      // axios.get(`http://192.168.108.22:8687/api/v1/antrians/${generatedId}`)
      apiRepository.getTicketInfo(generatedId)
        .then(_item => _item.data)
        .then(_data => {
          const listOrder = mapperCluster.onlyWithCluster(_data)
          const nowDay = moment(_data.date)
          if (listOrder.length > 0 && moment().diff(nowDay, 'days') === 0) {
            service.send({
              type: 'on:checked:ticket',
              data: _data,
              list: listOrder
            })
          } else {
            reject(new Error('NOT FOUND'))
          }
        })
        .catch(_err => reject(new Error(_err)))
      // window.loadingType = 1
      // window.checkIn = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.ERROR_GET_TICKET) {
      //     reject(new Error('error'))
      //   }
      // })
      // setTimeout(() => {
      //   if (window.ticket === '0001') {
      //     document.getElementById('ticket-1').click()
      //   } else if (window.ticket === '0002') {
      //     document.getElementById('ticket-2').click()
      //   } else if (window.ticket === '0003') {
      //     document.getElementById('ticket-3').click()
      //   } else if (window.ticket === '0006') {
      //     document.getElementById('ticket-7').click()
      //   } else if (window.ticket === '0004') {
      //     document.getElementById('ticket-5').click()
      //   } else if (window.ticket === '0007') {
      //     document.getElementById('ticket-8').click()
      //   }
      // }, 2500)
    })
  },
  postTicketFromAPI (data) {
    return new Promise((resolve, reject) => {
      const listCheckIn = data.filter(_item => _item.isCheckIn)
      const body = mapperCluster.bodyCheckIn(listCheckIn)
      apiRepository.checkInQueue(body)
        .then(_resp => _resp.data)
        .then(_data => {
          window.socket.once('RESPONSE:API-CHECKIN', (_evt) => {
            window.fetchKioskCheckIn.send({
              type: 'on:checkin:ticket'
            })
          })
        })
        .catch(_err => reject(new Error(_err)))
      // window.loadingType = 2
      // window.checkIn = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.ERROR_GET_TICKET) {
      //     reject(new Error('error'))
      //   }
      // })
      // setTimeout(() => {
      //   document.getElementById('ticket-9').click()
      // }, 2500)
    })
  }
}
