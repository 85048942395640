<template>
  <label class="d-block card-dokter-check"  :class="checkData ? 'active-checkbox' : ''" :for="`checkbox-${detail.id}`">
    <div class="media h-100">
      <input type="checkbox" :id="`checkbox-${detail.id}`" v-model="checkData" class="accent-checkbox align-self-center mr-13">
          <div class="media-body h-100">
              <div class="d-flex flex-column align-items-start h-100">
                <p class="fs-18 fw-700 c-deep  mb-auto">{{detail.dokterName}}</p>
                <div class="d-flex w-100">
                  <div class="flex-grow-1 fs-18 fw-500 c-primary mb-0">
                    {{detail.spesialis}}
                  </div>
                  <label class="align-self-end mb-0  fs-18 fw-500" :class="detail.isLate ? 'c-warning-A fw-700': 'c-primary'">
                    <template v-if="detail.isLate">
                      <img src="@/assets/img/info-warning.png">
                    </template>
                    {{detail.sessionStart}}
                  </label>
                </div>
              </div>
          </div>
    </div>
  </label>
</template>

<script>
export default {
  data () {
    return {
      checkData: true
    }
  },
  watch: {
    checkData (val) {
      window.fetchKioskCheckIn.send({
        type: 'select:Pasien',
        data: {
          index: this.indexKey,
          value: val
        }
      })
      window.checkIn.set({ type: 'reset' })
    }
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    indexKey: {
      type: Number
    }
  }
}
</script>
